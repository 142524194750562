import React from "react";
import "./Button.scss";
import BoogieMan from "../../assets/boogieman.png";

type TButtonType = "primary" | "secondary";

interface IButton {
  children: React.ReactNode;
  onClick?: (event?: any, mark?: number) => void;
  isDisabled?: boolean;
  btnType?: TButtonType;
  maximize?: boolean;
  type?: "button" | "submit" | "reset";
}

const Button: React.FC<IButton> = (props) => {
  const {
    children,
    onClick,
    isDisabled,
    btnType = "primary",
    maximize = false,
    type = "button",
  } = props;

  const handleClick = () => {
    onClick && onClick();
  };

  return (
    <button
      className={`button button--${btnType} button--${
        maximize ? "maximize" : ""
      }`}
      onClick={handleClick}
      disabled={isDisabled}
      type={type}
    >
      {children}
      {btnType === "primary" && <img src={BoogieMan} alt="" />}
    </button>
  );
};

export default Button;
