import React, { useEffect, useRef, useState } from "react";
import "./Quiz.scss";
import QuizProgressBar from "../../components/QuizProgressBar/QuizProgressBar";
import question from "../../data/questions.json";
import Answer from "../../components/Answer/Answer";
import { useNavigate } from "react-router-dom";
import MediaQuery from "react-responsive";
import { useDispatch, useSelector } from "react-redux";
import { addToTotal } from "../../reducers/markReducer";
import { Form, Formik } from "formik";
import { RootState } from "../../store";
import Layout from "../../hoc/Layout/Layout";
import Section from "../../hoc/Section/Section";

interface IQuiz {}

const Quiz: React.FC<IQuiz> = (props) => {
  const [currentQuestion, setCurrentQuestion] = useState<number>(0);
  const { total } = useSelector((state: RootState) => state.mark);
  const navigator = useNavigate();

  const dispatch = useDispatch();

  const formikRef = useRef<any>(null);
  const layoutRef = useRef<HTMLDivElement>(null);
  const titleRef = useRef<HTMLDivElement>(null);

  const handleSubmit = (values: any, actions: any) => {
    dispatch(addToTotal(values.mark));

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });

    actions.resetForm({
      values: { mark: -1 },
    });
    if (currentQuestion < question.length - 1) {
      setCurrentQuestion((prev) => prev + 1);
    } else {
      if (layoutRef.current) {
        layoutRef.current.classList.remove("page-entry");
        layoutRef.current.classList.add("page-exit");
      }
      setTimeout(() => {
        navigator(`/result/${total}`);
      }, 500);
    }
  };

  useEffect(() => {
    if (titleRef.current) {
      titleRef.current.innerHTML = `${question[currentQuestion].title}`;
    }
  }, [titleRef, currentQuestion]);

  return (
    <Layout hideFooter ref={layoutRef}>
      <Section className="quiz">
        <div className="quiz-wrapper">
          <MediaQuery maxWidth={767}>
            <QuizProgressBar
              amountOfQuestion={question.length}
              currentQuestion={currentQuestion}
            />
          </MediaQuery>
          <div className="quiz-wrapper__image">
            <MediaQuery maxWidth={767}>
              <img
                src={`/assets/quiz/${currentQuestion + 1}-mobile.png`}
                alt=""
              />
            </MediaQuery>
            <MediaQuery minWidth={768}>
              <img src={`/assets/quiz/${currentQuestion + 1}.png`} alt="" />
            </MediaQuery>
          </div>
          <div className="quiz-wrapper__content">
            <MediaQuery minWidth={768}>
              <QuizProgressBar
                amountOfQuestion={question.length}
                currentQuestion={currentQuestion}
              />
            </MediaQuery>
            <div className="quiz-wrapper__title" ref={titleRef}></div>
            <Formik
              innerRef={formikRef}
              initialValues={{ mark: -1 }}
              onSubmit={handleSubmit}
            >
              {(props) => {
                return (
                  <Form>
                    <div className="quiz-wrapper__answers">
                      {question[currentQuestion].questions.map(
                        (item, index) => {
                          return (
                            <Answer
                              text={item.text}
                              mark={item.mark}
                              key={index}
                              name="mark"
                            />
                          );
                        }
                      )}
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </Section>
    </Layout>
  );
};

export default Quiz;
