// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  max-width: 100%;
  margin: 1rem;
  padding: 1rem;
  background-color: #3E444B;
  color: white;
  animation-name: copy;
  animation-duration: 1000ms;
  animation-fill-mode: forwards;
  opacity: 0;
  z-index: 9999;
  border-radius: 16px;
}
@media only screen and (min-width: 1280px) {
  .modal {
    position: absolute;
    top: -100%;
    right: 0;
    width: max-content;
    height: max-content;
  }
}

@keyframes copy {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Modal/Modal.scss"],"names":[],"mappings":"AAEA;EACE,eAAA;EACA,SAAA;EACA,OAAA;EACA,QAAA;EACA,eAAA;EACA,YAAA;EACA,aAAA;EACA,yBAAA;EACA,YAAA;EACA,oBAAA;EACA,0BAAA;EACA,6BAAA;EACA,UAAA;EACA,aAAA;EACA,mBAAA;AADF;AAGE;EAjBF;IAkBI,kBAAA;IACA,UAAA;IACA,QAAA;IACA,kBAAA;IACA,mBAAA;EAAF;AACF;;AAGA;EACE;IACE,UAAA;EAAF;EAGA;IACE,UAAA;EADF;EAIA;IACE,UAAA;EAFF;EAKA;IACE,UAAA;EAHF;AACF","sourcesContent":["@import \"../../scss/variables.scss\";\n\n.modal {\n  position: fixed;\n  bottom: 0;\n  left: 0;\n  right: 0;\n  max-width: 100%;\n  margin: 1rem;\n  padding: 1rem;\n  background-color: $grey;\n  color: white;\n  animation-name: copy;\n  animation-duration: 1000ms;\n  animation-fill-mode: forwards;\n  opacity: 0;\n  z-index: 9999;\n  border-radius: 16px;\n\n  @media only screen and (min-width: $desktop) {\n    position: absolute;\n    top: -100%;\n    right: 0;\n    width: max-content;\n    height: max-content;\n  }\n}\n\n@keyframes copy {\n  0% {\n    opacity: 0;\n  }\n\n  25% {\n    opacity: 1;\n  }\n\n  75% {\n    opacity: 1;\n  }\n\n  100% {\n    opacity: 0;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
