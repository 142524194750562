// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.answer {
  min-height: 32px;
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  grid-gap: 0.75rem;
  padding: 15px 20px;
  font-weight: 400;
  text-align: left;
  border: 1px solid #E8E8E8;
  border-radius: 1rem;
  transition: 180ms;
  background-color: transparent;
  color: white;
}
@media (hover: hover) {
  .answer:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Answer/Answer.scss","webpack://./src/scss/variables.scss"],"names":[],"mappings":"AAEA;EACE,gBAAA;EACA,WAAA;EACA,YAAA;EAEA,eAAA;EAEA,aAAA;EACA,uBAAA;EACA,iBCcQ;EDZR,kBAAA;EACA,gBAAA;EAEA,gBAAA;EAEA,yBAAA;EACA,mBAAA;EAEA,iBAAA;EACA,6BAAA;EACA,YAAA;AAPF;AASE;EAEE;IACE,0CAAA;EARJ;AACF","sourcesContent":["@import '../../scss/variables.scss';\n\n.answer {\n  min-height: 32px;\n  width: 100%;\n  height: 100%;\n\n  cursor: pointer;\n\n  display: flex;\n  align-items: flex-start;\n  grid-gap: $halfGap;\n\n  padding: 15px 20px;\n  font-weight: 400;\n\n  text-align: left;\n\n  border: 1px solid $answerBorderColor;\n  border-radius: 1rem;\n\n  transition: 180ms;\n  background-color: transparent;\n  color: white;\n\n  @media (hover: hover) {\n\n    &:hover {\n      background-color: rgba(255, 255, 255, 0.2);\n    }\n  }\n}","$desktop: 1280px;\n$largeTablet: 992px;\n$smallTablet: 768px;\n\n$containerDesktop: 1206px;\n$containerLargeTablet: 944px;\n$conatinerSmallTablet: 690px;\n\n$backgroundColor: rgb(16, 24, 32);\n$whiteColor: #FFFFFF;\n$grey: #3E444B;\n$lightGrey: #AEAEAE;\n$midGrey: #757575;\n$contactsHoverGrey: #D4D4D4;\n$lime: #C4D600;\n$black: #000000;\n$veryDarkPurple: #4F05AC;\n$purple: #7818F1;\n$darkPurple: #6312C8;\n$iconGrey: #5C5C5C;\n$versionGrey: #999999;\n\n$headerHeight: 54px;\n\n$gap: 1.5rem;\n$halfGap: 0.75rem;\n\n$desktopFontSize1: 54px;\n$desktopFontSize2: 32px;\n$desktopFontSize3: 27px;\n$desktopFontSize4: 24px;\n$desktopFontSize5: 29px;\n$desktopFontSize6: 14px;\n$desktopFontSize7: 22px;\n$desktopFontSize8: 18px;\n\n$answerBorderColor: #E8E8E8;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
