import React, { useRef } from "react";
import Layout from "../../hoc/Layout/Layout";
import vacancies from "../../data/vacancies.json";
import Card from "../../components/Card/Card";
import Section from "../../hoc/Section/Section";
import ContentLayout from "../../hoc/ContentLayout/ContentLayout";
import "./Vacancies.scss";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

const Vacancies = () => {
  const layoutRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const { total } = useSelector((state: RootState) => state.mark);

  const handleClick = () => {
    if (layoutRef.current) {
      layoutRef.current.classList.remove("page-entry");
      layoutRef.current.classList.add("page-exit");
    }
    setTimeout(() => {
      navigate(`/result/${total > -1 ? total : 0}`);
    }, 1000);
  };

  return (
    <Layout ref={layoutRef}>
      <Section className="vacancies">
        <ContentLayout
          page="vacancies"
          title={
            <>
              Актуальные
              <br />
              вакансии
            </>
          }
          subtitle={
            <>
              Нам нравится твоя готовность действовать!
              <br />
              Здесь мы собрали все ссылки
              <br />
              на карьерные сайты компаний
            </>
          }
          clickHandler={handleClick}
          buttonTitle="Вернуться к дайджесту"
        >
          {vacancies
            .sort(() => Math.random() - 0.5)
            .map((item, index) => {
              return (
                <Card
                  key={index}
                  link={item.link}
                  type="vacancies"
                  imageLink={item.imageLink}
                  title={item.title}
                />
              );
            })}
        </ContentLayout>
      </Section>
    </Layout>
  );
};

export default Vacancies;
