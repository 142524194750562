import React from "react";
import "./Contacts.scss";

const Contacts = () => {
  return (
    <div className="contacts">
      <div className="contacts__email">
        <a href="mailto:hello@budu.jobs">hello@budu.jobs</a>
      </div>
    </div>
  );
};

export default Contacts;