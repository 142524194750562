// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contacts {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  grid-gap: 12px;
}
@media only screen and (min-width: 768px) {
  .contacts {
    grid-gap: 39px;
    flex-direction: row-reverse;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Contacts/Contacts.scss"],"names":[],"mappings":"AAEA;EAEE,aAAA;EACA,sBAAA;EACA,qBAAA;EACA,cAAA;AAFF;AAIE;EAPF;IAQI,cAAA;IACA,2BAAA;EADF;AACF","sourcesContent":["@import \"../../scss/variables.scss\";\n\n.contacts {\n\n  display: flex;\n  flex-direction: column;\n  align-items: flex-end;\n  grid-gap: 12px;\n\n  @media only screen and (min-width: $smallTablet) {\n    grid-gap: 39px;\n    flex-direction: row-reverse;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
