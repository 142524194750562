import React from "react";
import "./scss/normalize.scss";
import "./scss/general.scss";
import { Route, Routes } from "react-router-dom";
import Quiz from "./pages/Quiz/Quiz";
import ResultPage from "./pages/ResultPage/ResultPage";
import Home from "./pages/Home/Home";
import Vacancies from "./pages/Vacancies/Vacancies";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="result/:mark" element={<ResultPage />} />
        <Route path="quiz" element={<Quiz />} />
        <Route path="vacancies" element={<Vacancies />} />
      </Routes>
    </div>
  );
}

export default App;
