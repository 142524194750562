import React, { useEffect, useRef, useState } from "react";
import "./Card.scss";

interface ICard {
  title: string;
  link: string;
  imageLink: string;
  type: string;
  delay?: string;
}

const Card: React.FC<ICard> = (props) => {
  const { title, link, imageLink, type, delay = "0" } = props;
  const [isIntersecting, setIsIntersecting] = useState<boolean>(false);
  const cardRef = useRef<HTMLDivElement>(null);

  if (cardRef.current) {
    cardRef.current.style.transitionDelay = `${delay}ms`;
  }

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsIntersecting(entry.isIntersecting);
      },
      { rootMargin: "0px" }
    );
    if (cardRef.current) {
      observer.observe(cardRef.current);
    }
    return () => {
      observer.disconnect();
    };
  }, [cardRef]);

  const cardRefTitle = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (cardRefTitle.current) {
      cardRefTitle.current.innerHTML = `${title}`
    }
  }, [cardRefTitle, title]);

  return (
    <div
      className={`card card--${isIntersecting ? "reveal" : "hide"}`}
      ref={cardRef}
    >
      <a href={link} target="_blank" rel="noreferrer">
        <div className="card__image">
          <img src={`/assets/${type}/${imageLink}`} alt="" />
        </div>
        <div className="card__title" ref={cardRefTitle}>
        </div>
      </a>
    </div>
  );
};

export default Card;
