import React, { ForwardedRef, forwardRef } from "react";
import "./Section.scss";

interface ISection {
  children: React.ReactNode;
  className: string;
  ref?: ForwardedRef<any>;
}

const Section: React.FC<ISection> = forwardRef(function Section(
  props,
  ref: ForwardedRef<any>
) {
  const { children, className } = props;
  return (
    <section className={className} ref={ref}>
      <div className="container">{children}</div>
    </section>
  );
});

export default Section;
