import React, { useEffect, useState } from "react";
import "./Mark.scss";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import Bone from "../../assets/bone.png";
import { RootState } from "../../store";

const Mark = () => {
  const { total } = useSelector((state: RootState) => state.mark);
  const dispatch = useDispatch();
  const [results, setResults] = useState<number>(0);
  const params = useParams();

  useEffect(() => {
    if (total === -1) {
      setResults(Number(params.mark));
    } else {
      setResults(total);
    }
  }, [dispatch, total, params]);

  return (
    <div className="mark">
      <div className="mark-title">Баллов:</div>
      <div className="mark-total">
        <div className="mark-result">{results}</div>
        <div className="mark-separator">
          <img src={Bone} alt="" />
        </div>
        <div className="mark-all">100</div>
      </div>
    </div>
  );
};

export default Mark;
