import React, { useEffect, useRef, useState } from "react";
import Button from "../../components/Button/Button";
import "./ContentLayout.scss";

interface IContentLayout {
  title: React.ReactNode;
  subtitle: React.ReactNode;
  children: React.ReactNode;
  clickHandler?: () => void;
  buttonTitle?: string;
  page?: string;
}

const ContentLayout: React.FC<IContentLayout> = (props) => {
  const { title, subtitle, children, clickHandler, buttonTitle, page } = props;

  const [isIntersecting, setIsIntersecting] = useState<boolean>(false);
  const buttonRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsIntersecting(entry.isIntersecting);
      },
      { rootMargin: "-100px" }
    );
    if (buttonRef.current) {
      observer.observe(buttonRef.current);
    }
    return () => {
      observer.disconnect();
    };
  }, [buttonRef]);

  const handleClick = () => {
    clickHandler && clickHandler();
  };

  return (
    <div className="content-layout">
      <div className="content-layout__header">
        <div className="content-layout__title">{title}</div>
        <div className="content-layout__subtitle">{subtitle}</div>
      </div>
      <div
        className={`content-layout__body content-layout__body--${page}`}
        ref={buttonRef}
      >
        {children}
      </div>
      {clickHandler && (
        <div
          className={`content-layout__button-container content-layout__button-container--${
            isIntersecting ? "reveal" : ""
          }`}
        >
          <Button onClick={handleClick}>{buttonTitle}</Button>
        </div>
      )}
    </div>
  );
};

export default ContentLayout;
