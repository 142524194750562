import React from "react";
import "./Header.scss";
import Logo from "../../assets/logo_budu.svg";

const Header: React.FC = () => {
  return (
    <div className="container">
      <header className="header">
        <div className="header__logo">
          <img src={Logo} alt="БУДУ logo" />
        </div>
      </header>
    </div>
  );
};

export default Header;
