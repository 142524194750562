import React, { useEffect, useRef, useState } from "react";
import "./Results.scss";
import Bat from "../../assets/result-bat.png";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { getResults } from "../../helpers";
import { useParams } from "react-router-dom";

const Results = () => {
  const { total } = useSelector((state: RootState) => state.mark);
  const dispatch = useDispatch();
  const [results, setResults] = useState<any>({ title: "", subtitle: "" });
  const params = useParams();

  useEffect(() => {
    if (total === -1) {
      setResults(getResults(params.mark));
    } else {
      setResults(getResults(total));
    }
  }, [dispatch, total, params]);

  const subtitleRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (subtitleRef.current) {
      subtitleRef.current.innerHTML = `${results.subtitle}`;
    }
  }, [subtitleRef, results]);

  return (
    <div className="results">
      <div className="results__title">
        {results.title}
        <img src={Bat} alt="" />
      </div>
      <div className="results__subtitle" ref={subtitleRef}></div>
    </div>
  );
};

export default Results;
