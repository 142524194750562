// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 1rem;
}
@media only screen and (min-width: 768px) {
  .container {
    max-width: 690px;
  }
}
@media only screen and (min-width: 992px) {
  .container {
    max-width: 944px;
  }
}
@media only screen and (min-width: 1280px) {
  .container {
    max-width: 1206px;
  }
}
.container--collapsed {
  padding: 0;
}`, "",{"version":3,"sources":["webpack://./src/hoc/Section/Section.scss","webpack://./src/scss/variables.scss"],"names":[],"mappings":"AAEA;EACE,WAAA;EACA,eAAA;EACA,cAAA;EAEA,eAAA;AAFF;AAIE;EAPF;IAQI,gBCJmB;EDGrB;AACF;AAIE;EAZF;IAaI,gBCVmB;EDSrB;AACF;AAGE;EAhBF;IAiBI,iBCfe;EDejB;AACF;AAEE;EACE,UAAA;AAAJ","sourcesContent":["@import '../../scss/variables.scss';\n\n.container {\n  width: 100%;\n  max-width: 100%;\n  margin: 0 auto;\n  // padding: 10px 1rem 0 1rem;\n  padding: 0 1rem;\n\n  @media only screen and (min-width: $smallTablet) {\n    max-width: $conatinerSmallTablet;\n    // padding-top: $gap;\n  }\n\n  @media only screen and (min-width: $largeTablet) {\n    max-width: $containerLargeTablet;\n  }\n\n  @media only screen and (min-width: $desktop) {\n    max-width: $containerDesktop;\n  }\n\n  &--collapsed {\n    padding: 0;\n  }\n}","$desktop: 1280px;\n$largeTablet: 992px;\n$smallTablet: 768px;\n\n$containerDesktop: 1206px;\n$containerLargeTablet: 944px;\n$conatinerSmallTablet: 690px;\n\n$backgroundColor: rgb(16, 24, 32);\n$whiteColor: #FFFFFF;\n$grey: #3E444B;\n$lightGrey: #AEAEAE;\n$midGrey: #757575;\n$contactsHoverGrey: #D4D4D4;\n$lime: #C4D600;\n$black: #000000;\n$veryDarkPurple: #4F05AC;\n$purple: #7818F1;\n$darkPurple: #6312C8;\n$iconGrey: #5C5C5C;\n$versionGrey: #999999;\n\n$headerHeight: 54px;\n\n$gap: 1.5rem;\n$halfGap: 0.75rem;\n\n$desktopFontSize1: 54px;\n$desktopFontSize2: 32px;\n$desktopFontSize3: 27px;\n$desktopFontSize4: 24px;\n$desktopFontSize5: 29px;\n$desktopFontSize6: 14px;\n$desktopFontSize7: 22px;\n$desktopFontSize8: 18px;\n\n$answerBorderColor: #E8E8E8;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
