import React from "react";
import "./Hero.scss";

import TopLine from "../../assets/hero-top-line.svg";
import MiddleLine from "../../assets/hero-mid-line.svg";
import BottomLine from "../../assets/hero-bottom-line.svg";

import HeroPumpkin from "../../assets/hero-pumpkin.webp";
import HeroSkull from "../../assets/hero-skull.webp";

import HeroBat_1 from "../../assets/hero-bat_1.svg";
import HeroBat_2 from "../../assets/hero-bat_2.svg";
import HeroBat_3 from "../../assets/hero-bat_2.svg";
import HeroBat_4 from "../../assets/hero-bat_4.svg";
import HeroBat_5 from "../../assets/hero-bat_5.svg";
import HeroBat_6 from "../../assets/hero-bat_6.svg";
import HeroBat_7 from "../../assets/hero-bat_7.svg";
import MediaQuery from "react-responsive";

const Hero = () => {
  return (
    <div className="hero">
      <div className="hero__title">
        Свет мигает,
        <br />
        WI-FI отваливается,
        <br />в коллективе одни упыри
      </div>
      <div className="hero__subtitle">
        Это — кошмар, <br className="hero__subtitle--desktop" />с которым
        справятся
        <br /> только самые стойкие
      </div>
      <img src={TopLine} alt="" className="hero__top-line" />
      <img src={MiddleLine} alt="" className="hero__middle-line" />
      <img src={BottomLine} alt="" className="hero__bottom-line" />
      <img src={HeroSkull} alt="" className="hero__skull" />
      <img src={HeroPumpkin} alt="" className="hero__pumpkin" />
      <MediaQuery minWidth={1280}>
        <img src={HeroBat_1} alt="" className="hero__bat_1" />
        <img src={HeroBat_2} alt="" className="hero__bat_2" />
        <img src={HeroBat_3} alt="" className="hero__bat_3" />
        <img src={HeroBat_4} alt="" className="hero__bat_4" />
      </MediaQuery>
      <MediaQuery maxWidth={1199}>
        <img src={HeroBat_5} alt="" className="hero__bat_5" />
        <img src={HeroBat_6} alt="" className="hero__bat_6" />
        <img src={HeroBat_7} alt="" className="hero__bat_7" />
      </MediaQuery>
    </div>
  );
};

export default Hero;
