import React, { useEffect, useRef, useState } from "react";
import Layout from "../../hoc/Layout/Layout";
import "./Home.scss";
import Hero from "../../components/Hero/Hero";
import Tip from "../../components/Tip/Tip";
import { useNavigate } from "react-router-dom";
import Section from "../../hoc/Section/Section";
import ContentLayout from "../../hoc/ContentLayout/ContentLayout";
import Preview from "../../components/Preview/Preview";

const Home = () => {
  const navigate = useNavigate();
  const [isPreview, setIsPreview] = useState<boolean>(true);
  // const [isPreview, setIsPreview] = useState<boolean>(window.innerWidth < 1280);

  const sectionRef = useRef<any>(null);

  const handleClick = () => {
    if (sectionRef.current) {
      sectionRef.current.classList.add("page-exit");
    }
    setTimeout(() => {
      navigate("/quiz");
    }, 500);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [isPreview]);

  // return isPreview ? (
  //   <Preview setIsPreview={setIsPreview} />
  // ) : (
  return (
    <Layout>
      <Section className="home" ref={sectionRef}>
        <Hero />
        <ContentLayout
          page="home"
          title={<>Воттахелл</>}
          subtitle={
            <>
              Хэллоуин — время страшных костюмов
              <br />
              и бутафорской крови. Но что, если ужасы
              <br />
              бывают не только в жизни, но и на работе?
            </>
          }
          clickHandler={handleClick}
          buttonTitle="Погрузиться в ужас"
        >
          <>
            <Tip title="Время теста" imageName="clock">
              Всего
              <br />2 минуты
            </Tip>
            <Tip title="Что получишь?" imageName="scream" delay="100">
              Проверишь свою
              <br />
              стойкость
            </Tip>
            <Tip title="Результаты" imageName="award" delay="150">
              Узнаешь, как
              <br />
              избежать жесть
            </Tip>
          </>
        </ContentLayout>
      </Section>
    </Layout>
  );
};

export default Home;
