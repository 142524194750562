import React from "react";
import "./Footer.scss";
import Icon from "../Icon/Icon";
import Contacts from "../Contacts/Contacts";

interface IFooter {
  hideFooter?: boolean;
}

const Footer: React.FC<IFooter> = (props) => {
  const { hideFooter } = props;
  return !hideFooter ? (
    <div className="container">
      <footer className="footer">
        <div className="footer__top">
          <Contacts />
        </div>
        <div className="footer__bottom">
          <div className="footer__socials">
            <div
              className="socials__social-icon"
              onClick={() => {
                window.open("https://t.me/budujobs", "_blank");
              }}
            >
              <Icon name="telegram" />
            </div>
            <div
              className="socials__social-icon"
              onClick={() => {
                window.open("https://vk.com/budujobs", "_blank");
              }}
            >
              <Icon name="vk" />
            </div>
          </div>
        </div>
      </footer>
    </div>
  ) : null;
};

export default Footer;
