import React, { useEffect, useRef } from "react";
import "./Answer.scss";
import Icon from "../Icon/Icon";
import { useField } from "formik";

interface IAnswer {
  text: string;
  mark: number;
  name: string;
  clickHandler?: () => void;
}

const Answer: React.FC<IAnswer> = (props) => {
  const { text, mark, name, clickHandler } = props;
  const [field, meta, helpers] = useField(props);

  const buttonRef = useRef<HTMLButtonElement>(null);

  const handleClick = (event: any) => {
    helpers.setValue(mark);
  };

  useEffect(() => {
    if (buttonRef.current) {
      buttonRef.current.innerHTML = `${text}`;
    }
  }, [buttonRef, text, mark]);

  return (
    <button
      className="answer"
      type="submit"
      onClick={handleClick}
      ref={buttonRef}
    ></button>
  );
};

export default Answer;
