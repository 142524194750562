export const getResults = (total) => {
  console.log("total", total);
  console.log("typeof total", typeof total);
  if (total < 25) {
    return {
      title: (
        <>
          Спасибо, что живой!
          <br />
          {total} баллов по шкале кошмара
        </>
      ),
      subtitle:
        "Этот опыт похож на плохой фильм ужасов —<br />такой же пугающе-непонятный и&nbsp;с&nbsp;разваливающимися декорациями.",
    };
  } else if (total < 50) {
    return {
      title: (
        <>
          Впереди — только светлое будущее!
          <br />
          {total} баллов по шкале кошмара
        </>
      ),
      subtitle:
        "Все черти из преисподней сбежались посмотреть на&nbsp;того, кто&nbsp;выбрался из&nbsp;этого ада!<br />Аплодируем вместе с&nbsp;ними.",
    };
  } else if (total < 75) {
    return {
      title: (
        <>
          Это точно был офис?
          <br />
          {total} баллов по шкале кошмара
        </>
      ),
      subtitle:
        "Было похоже на дом с&nbsp;паранормальщиной.<br />Страшно, очень страшно.<br />Если бы мы знали, что&nbsp;это такое, мы не знаем, что&nbsp;это&nbsp;такое.",
    };
  } else {
    return {
      title: (
        <>
          Десятый круг ада?
          <br />
          100 баллов по шкале кошмара
        </>
      ),
      subtitle:
        "Сейчас Данте пожалел, что придумал в&nbsp;«Божественной комедии» всего девять.<br />На десятом должен находиться такой трудовой опыт.",
    };
  }
};
