import React, { useRef, useState } from "react";
import Layout from "../../hoc/Layout/Layout";
import digests from "../../data/digests.json";
import Card from "../../components/Card/Card";
import "./ResultPage.scss";
import { useNavigate } from "react-router-dom";
import Icon from "../../components/Icon/Icon";
import Pumpkin from "../../assets/pumpkin_angle.png";
import Skull from "../../assets/result-skull.webp";
import MediaQuery from "react-responsive";
import Section from "../../hoc/Section/Section";
import ContentLayout from "../../hoc/ContentLayout/ContentLayout";
import Results from "../../components/Results/Results";
import Mark from "../../components/Mark/Mark";
import Modal from "../../components/Modal/Modal";
import copy from "copy-to-clipboard";

const ResultPage = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const navigate = useNavigate();

  const layoutRef = useRef<HTMLDivElement>(null);

  const handleClick = () => {
    if (layoutRef.current) {
      layoutRef.current.classList.remove("page-entry");
      layoutRef.current.classList.add("page-exit");
    }
    setTimeout(() => {
      navigate("/vacancies");
    }, 1000);
  };

  const handleCopy = (event: any) => {
    setIsModalOpen(true);
    copy(window.location.href);
  };

  return (
    <Layout ref={layoutRef}>
      <Section className="result">
        <Results />
        <div className="result__content">
          <div className="result__content--left">
            <Mark />
            <div className="invite">
              <MediaQuery minWidth={768}>
                <div className="invite__title--desktop">
                  Пригласить друга
                  <br />к воротам ада
                </div>
              </MediaQuery>
              <div className="invite__icon-container">
                <Modal isOpen={isModalOpen} setIsOpen={setIsModalOpen}>
                  Скопировано
                </Modal>
                <div className="invite__icon" onClick={handleCopy}>
                  <Icon name="copy" width={24} height={24} />
                </div>
                <a
                  href="https://t.me/share/url?url=https://halloween.budu.jobs/&text=Воттахелл - Пройти тест"
                  rel="noreferrer"
                  target="_blank"
                >
                  <div className="invite__icon telegram">
                    <Icon name="telegram" width={24} height={24} />
                  </div>
                </a>
                <a
                  href="https://vk.com/share.php?url=https://halloween.budu.jobs/"
                  rel="noreferrer"
                  target="_blank"
                >
                  <div className="invite__icon">
                    <Icon name="vk" width={32} height={32} />
                  </div>
                </a>
              </div>
            </div>
          </div>
          <MediaQuery maxWidth={767}>
            <div className="invite__title--mobile">
              Пригласить друга к воротам ада
            </div>
          </MediaQuery>
          <div className="result__content--right">
            <div className="shadow"></div>
            <img src={Skull} alt="" className="result__skull" />
          </div>
        </div>
        <div className="result__note">
          <div className="result__note-title">
            <span>
              Если не хочешь столкнуться с&nbsp;этими ужасами в&nbsp;реальной
              жизни, приходи <br />
              работать к&nbsp;тем, кто тебя ценит, грамотно менеджерит <br />
              и&nbsp;не&nbsp;даёт в&nbsp;обиду клиентам!
            </span>
            <img src={Pumpkin} alt="" />
          </div>
        </div>
      </Section>
      <Section className="digest">
        <ContentLayout
          title={<>Дайджест</>}
          subtitle={
            <>
              Выбирай самые интересные темы
              <br />и смотри, как работают классные компании
            </>
          }
          clickHandler={handleClick}
          buttonTitle="Откликнуться на вакансии"
        >
          {digests
            .sort(() => Math.random() - 0.5)
            .map((item, index) => {
              console.log("item", item);
              return (
                <Card
                  key={index}
                  link={item.link}
                  type="digest"
                  imageLink={item.imageLink}
                  title={item.title}
                  delay={String(50)}
                />
              );
            })}
        </ContentLayout>
      </Section>
    </Layout>
  );
};

export default ResultPage;
