import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import React, { ForwardedRef, forwardRef, useEffect } from "react";
import "./Layout.scss";
import { useDispatch } from "react-redux";
import { setPageType } from "../../reducers/headerReducer";

interface ILayout {
  children: React.ReactNode;
  hideFooter?: boolean;
  ref?: ForwardedRef<any>;
}

const Layout: React.FC<ILayout> = forwardRef(function Layout(
  props,
  ref: ForwardedRef<any>
) {
  const { children, hideFooter } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setPageType(window.location.pathname === "/"));
  }, [window.location.pathname]);

  return (
    <div className="page-entry" ref={ref}>
      <Header />
      <main className="main">{children}</main>
      <Footer hideFooter={hideFooter} />
    </div>
  );
});

export default Layout;
