import React, { useEffect, useRef, useState } from "react";
import "./Tip.scss";

interface ITip {
  title: string;
  imageName: string;
  children: React.ReactNode;
  delay?: string;
}

const Tip: React.FC<ITip> = (props) => {
  const { title, children, imageName, delay = "0" } = props;
  const [isIntersecting, setIsIntersecting] = useState<boolean>(false);
  const tipRef = useRef<HTMLDivElement>(null);

  if (tipRef.current) {
    tipRef.current.style.transitionDelay = `${delay}ms`;
  }

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsIntersecting(entry.isIntersecting);
      },
      { rootMargin: "-50px" }
    );
    if (tipRef.current) {
      observer.observe(tipRef.current);
    }
    return () => {
      observer.disconnect();
    };
  }, [tipRef]);

  return (
    <div
      className={`tip tip--${isIntersecting ? "reveal" : "hide"}`}
      ref={tipRef}
    >
      <img src={`/assets/tips/${imageName}.webp`} alt="" />
      <div className="tip__title">{title}</div>
      <div className="tip__body">{children}</div>
    </div>
  );
};

export default Tip;
