// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (min-width: 1280px) {
  .vacancies {
    padding-top: 2rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/Vacancies/Vacancies.scss"],"names":[],"mappings":"AAIE;EAFF;IAGI,iBAAA;EAFF;AACF","sourcesContent":["@import '../../scss/variables.scss';\n\n.vacancies {\n\n  @media only screen and (min-width: $desktop) {\n    padding-top: 2rem;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
