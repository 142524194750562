// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.quiz-progress-bar {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(0px, 1fr));
  grid-gap: 0.5rem;
  height: 0.5rem;
}
.quiz-progress-bar__step {
  border-radius: 0.5rem;
  background-color: rgba(127, 198, 21, 0.2);
}
.quiz-progress-bar__step--current {
  position: relative;
}
.quiz-progress-bar__step--current::before {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  border-radius: 0.5rem;
  width: 33%;
  height: 100%;
  background-color: rgb(127, 198, 21);
}
.quiz-progress-bar__step--passed {
  position: relative;
}
.quiz-progress-bar__step--passed::before {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  border-radius: 0.5rem;
  width: 100%;
  height: 100%;
  background-color: rgb(127, 198, 21);
}`, "",{"version":3,"sources":["webpack://./src/components/QuizProgressBar/QuizProgressBar.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,yDAAA;EACA,gBAAA;EACA,cAAA;AADF;AAGE;EACE,qBAAA;EACA,yCAAA;AADJ;AAGI;EACE,kBAAA;AADN;AAGM;EACE,kBAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,qBAAA;EACA,UAAA;EACA,YAAA;EACA,mCAAA;AADR;AAKI;EACE,kBAAA;AAHN;AAKM;EACE,kBAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,qBAAA;EACA,WAAA;EACA,YAAA;EACA,mCAAA;AAHR","sourcesContent":["@import '../../scss/variables.scss';\n\n.quiz-progress-bar {\n  display: grid;\n  grid-template-columns: repeat(auto-fit, minmax(0px, 1fr));\n  grid-gap: .5rem;\n  height: .5rem;\n\n  &__step {\n    border-radius: .5rem;\n    background-color: rgba(127, 198, 21, 0.2);\n\n    &--current {\n      position: relative;\n\n      &::before {\n        position: absolute;\n        top: 0;\n        left: 0;\n        content: \"\";\n        border-radius: .5rem;\n        width: 33%;\n        height: 100%;\n        background-color: rgba(127, 198, 21, 1);\n      }\n    }\n\n    &--passed {\n      position: relative;\n\n      &::before {\n        position: absolute;\n        top: 0;\n        left: 0;\n        content: \"\";\n        border-radius: .5rem;\n        width: 100%;\n        height: 100%;\n        background-color: rgba(127, 198, 21, 1);\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
