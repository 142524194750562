// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.results__title {
  position: relative;
  font-weight: 500;
  font-size: 32px;
}
@media only screen and (min-width: 1280px) {
  .results__title {
    font-size: 96px;
  }
}
.results__title img {
  position: absolute;
  top: 0;
  z-index: 0;
  width: 50px;
  left: 200px;
  top: -10px;
}
@media only screen and (min-width: 1280px) {
  .results__title img {
    left: 50%;
    width: 128px;
  }
}
.results__subtitle {
  color: rgba(255, 255, 255, 0.37);
  padding-top: 0.5rem;
  font-size: 14px;
}
@media only screen and (min-width: 1280px) {
  .results__subtitle {
    font-size: 24px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Results/Results.scss"],"names":[],"mappings":"AAIE;EACE,kBAAA;EACA,gBAAA;EACA,eAAA;AAHJ;AAKI;EALF;IAMI,eAAA;EAFJ;AACF;AAII;EACE,kBAAA;EACA,MAAA;EACA,UAAA;EACA,WAAA;EACA,WAAA;EACA,UAAA;AAFN;AAIM;EARF;IASI,SAAA;IACA,YAAA;EADN;AACF;AAKE;EACE,gCAAA;EACA,mBAAA;EACA,eAAA;AAHJ;AAKI;EALF;IAMI,eAAA;EAFJ;AACF","sourcesContent":["@import \"../../scss/variables.scss\";\n\n.results {\n\n  &__title {\n    position: relative;\n    font-weight: 500;\n    font-size: 32px;\n\n    @media only screen and (min-width: $desktop) {\n      font-size: 96px;\n    }\n\n    img {\n      position: absolute;\n      top: 0;\n      z-index: 0;\n      width: 50px;\n      left: 200px;\n      top: -10px;\n\n      @media only screen and (min-width: $desktop) {\n        left: 50%;\n        width: 128px;\n      }\n    }\n  }\n\n  &__subtitle {\n    color: rgba(255, 255, 255, 0.37);\n    padding-top: .5rem;\n    font-size: 14px;\n\n    @media only screen and (min-width: $desktop) {\n      font-size: 24px;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
