import React, { SetStateAction, useEffect } from "react";
import "./Modal.scss";

interface IModal {
  children?: React.ReactNode;
  isOpen?: boolean;
  setIsOpen: React.Dispatch<SetStateAction<boolean>>;
}

const Modal: React.FC<IModal> = (props) => {
  const { children, isOpen, setIsOpen } = props;

  useEffect(() => {
    const timer = setTimeout(() => {
      console.log("timer");
      setIsOpen(false);
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [isOpen]);

  return isOpen ? <div className="modal">{children}</div> : null;
};

export default Modal;
